import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-propuesta',
  templateUrl: './propuesta.component.html',
  styleUrls: ['./propuesta.component.css'],
})
export class PropuestaComponent implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;

  arrSolComplete = [] as any;
  arrOrders = [] as any;
  importes = [] as any;
  ultimoDia: string;
  arrSolFiltered = [] as any;
  arrPropuesta = [] as any;
  selectAll = false;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;

  constructor(
    private router: Router,
    private dealMemoService: DealMemoService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;

    const hoy = new Date();
    const ultimoDiaPermitido = this.getLastDay(hoy);
    console.log('Ultimo dia permitido', ultimoDiaPermitido);
    const year = ultimoDiaPermitido.getFullYear();
    const month = (ultimoDiaPermitido.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const day = ultimoDiaPermitido.getDate().toString().padStart(2, '0');
    this.ultimoDia = `${year}-${month}-${day}` + 'T23:59:59';

    this.getPurchaseOrder();
  }

  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log('Ordenes de compra', res);
        this.arrOrders = res;
        this.arrSolComplete = [] as any;

        // Recorrer el arreglo de ordenes y por cada orden recorrer el arreglo de solicitudes
        for (let index = 0; index < this.arrOrders.length; index++) {
          const elementOrder = this.arrOrders[index];
          // console.log(elementOrder);
          if (elementOrder.solicitudes.length > 0) {
            elementOrder.solicitudes.forEach(elementRequest => {
              elementRequest.nombreProveedor = elementOrder.nombreProveedor;
              elementRequest.rfc = elementOrder.rfc;
              elementRequest.numeroProveedor = elementOrder.numeroProveedor;
              elementRequest.actividad = elementOrder.actividad;
              elementRequest.provider = elementOrder.proveedor;
              elementRequest.tipoPago = elementOrder.tipoPago;
              elementRequest.idPo = elementOrder.orderCounter;
              elementRequest.seleccionado = false;
              this.arrSolComplete.push(elementRequest);
            });
          }
        }

        // Ordenar el arreglo de solicitudes por countRequest
        this.arrSolComplete.sort((a, b) => {
          return a.countRequest - b.countRequest;
        });
        // console.log(this.arrSolComplete);
        this.arrSolFiltered = this.filterSolicitudes(this.arrSolComplete);
        console.log('Solicitudes', this.arrSolFiltered);
        this.filterSolicitudesByTipoPago();

        // Recorrer el arreglo de solicitudes filtrado para sumar los importes, iva y total y guardarlos en un arreglo independiente
        this.arrSolFiltered.forEach(elementRequest => {
          let sumaImporte = 0;
          let sumaIva = 0;
          let sumaOtrasCont = 0;
          let sumaRetIva = 0;
          let sumaRetIsr = 0;
          let sumaTotal = 0;
          elementRequest.valores.forEach(elementValores => {
            sumaImporte += elementValores.importe;
            sumaIva += elementValores.iva;
            sumaOtrasCont += elementValores.otrasCont || 0;
            sumaRetIva += elementValores.retIva || 0;
            sumaRetIsr += elementValores.retIsr || 0;
            sumaTotal += elementValores.total;
          });

          const objImportes = {
            importe: sumaImporte,
            iva: sumaIva,
            otrasCont: sumaOtrasCont,
            retIva: sumaRetIva,
            retIsr: sumaRetIsr,
            total: sumaTotal,
          };

          this.importes.push(objImportes);
        });
        // console.log(this.importes);
      });
  }

  getLastDay(date: Date): Date {
    // funcion para obtener el ultimo viernes de la semana actual
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 6);
    let lastFriday = new Date(date.setDate(diff));
    lastFriday = new Date(
      lastFriday.getFullYear(),
      lastFriday.getMonth(),
      lastFriday.getDate(),
      23,
      59,
      59
    );
    return lastFriday;
  }

  selectAllItems() {
    this.arrSolFiltered.forEach(element => {
      element.seleccionado = this.selectAll;
    });
    this.filterSolicitudesByTipoPago();
  }

  selectItem() {
    this.selectAll = this.arrSolFiltered.every(element => element.seleccionado);
    this.filterSolicitudesByTipoPago();
  }

  // Funcion para filtrar las solicitudes que su fechaPropuesta sea igual o menor al ultimo viernes siempre y cuando pagado sea false
  filterSolicitudes(arr) {
    const arrSol = arr.filter(element => {
      let fecha: Date;
      if (element.fechaPropuesta.seconds) {
        fecha = new Date(element.fechaPropuesta.seconds * 1000);
      } else {
        fecha = element.fechaPropuesta;
      }
      const fechaPropuesta = fecha;
      const fechaViernes = new Date(this.ultimoDia);
      return fechaPropuesta <= fechaViernes && !element.pagado;
    });
    return arrSol;
  }

  totales() {
    let sumaImporte = 0;
    let sumaIva = 0;
    let sumaOtrasCont = 0;
    let sumaRetIva = 0;
    let sumaRetIsr = 0;
    let sumaTotal = 0;

    this.importes.forEach(element => {
      sumaImporte += element.importe;
      sumaIva += element.iva;
      sumaOtrasCont += element.otrasCont;
      sumaRetIva += element.retIva;
      sumaRetIsr += element.retIsr;
      sumaTotal += element.total;
    });

    return {
      importe: sumaImporte,
      iva: sumaIva,
      otrasCont: sumaOtrasCont,
      retIva: sumaRetIva,
      retIsr: sumaRetIsr,
      total: sumaTotal,
    };
  }

  // funcion para crear arrays de solicitudes por tipo de pago
  filterSolicitudesByTipoPago() {
    // extraer primero los diferentes tipoPago

    // Crear un arreglo sin los elementos deseleccionados
    const arrFiltered = this.arrSolFiltered.filter(
      element => element.seleccionado
    );

    const tipoPagos = arrFiltered.map(element => element.tipoPago);
    const tipoPagosUnicos = tipoPagos.filter(
      (value, index, self) => self.indexOf(value) === index
    );

    this.arrPropuesta = [] as any;
    [...tipoPagosUnicos].forEach(element => {
      const arrSol = arrFiltered.filter(
        item => item.tipoPago === element && item.seleccionado
      );
      const objTipoPago = {
        tipoPago: element,
        solicitudes: arrSol,
      };
      this.arrPropuesta.push(objTipoPago);
    });
    console.log('Propuesta', this.arrPropuesta);
    this.totalesTipoPago();
  }

  // resumen de totales por tipo de pago
  totalesTipoPago() {
    this.arrPropuesta.forEach(element => {
      element.importes = [] as any;
      let sumaImporte = 0;
      let sumaIva = 0;
      let sumaOtrasCont = 0;
      let sumaRetIva = 0;
      let sumaRetIsr = 0;
      let sumaTotal = 0;
      element.solicitudes.forEach(item => {
        let importe = 0;
        let iva = 0;
        let otrasCont = 0;
        let retIva = 0;
        let retIsr = 0;
        let total = 0;
        item.valores.forEach(valores => {
          // Suma de importes por solicitud
          importe += valores.importe;
          iva += valores.iva || 0;
          otrasCont += valores.otrasCont || 0;
          retIva += valores.retIva || 0;
          retIsr += valores.retIsr || 0;
          total += valores.total;
          // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

          // Suma de importes por solicitud y por tipo de pago
          sumaImporte += valores.importe;
          sumaIva += valores.iva || 0;
          sumaOtrasCont += valores.otrasCont || 0;
          sumaRetIva += valores.retIva || 0;
          sumaRetIsr += valores.retIsr || 0;
          sumaTotal += valores.total;
          // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        });
        const objImportes = {
          importe,
          iva,
          otrasCont,
          retIva,
          retIsr,
          total,
        };
        element.importes.push(objImportes);
      });
      element.totales = {
        importe: sumaImporte,
        iva: sumaIva,
        otrasCont: sumaOtrasCont,
        retIva: sumaRetIva,
        retIsr: sumaRetIsr,
        total: sumaTotal,
      };
    });
  }

  downloadExcel() {
    const resumen = [];

    // Resumen <<<<<<<<<<<<<<
    this.arrPropuesta.forEach(element => {
      const objResumen = {
        TipoPago: 'PROPUESTA DE PAGOS ' + element.tipoPago,
        Importe: element.totales.importe,
        IVA: element.totales.iva || 0,
        OtrasCont: element.totales.otrasCont || 0,
        RetIVA: element.totales.retIva || 0,
        RetISR: element.totales.retIsr || 0,
        Total: element.totales.total,
      };
      resumen.push(objResumen);
    });
    resumen.push({
      TipoPago: 'TOTAL',
      Importe: this.totales().importe,
      IVA: this.totales().iva,
      OtrasCont: this.totales().otrasCont,
      RetIVA: this.totales().retIva,
      RetISR: this.totales().retIsr,
      Total: this.totales().total,
    });
    // >>>>>>>>>>>>>>

    // Crear y exportar el archivo Excel
    const worksheetResumen: XLSX.WorkSheet = XLSX.utils.json_to_sheet(resumen);

    // const worksheetSolicitudes: XLSX.WorkSheet =
    //   XLSX.utils.json_to_sheet(solicitudes);

    // Crear el libro de trabajo
    const workbook: XLSX.WorkBook = {
      SheetNames: ['Resumen'].concat(
        this.arrPropuesta.map(element => element.tipoPago)
      ),
      Sheets: {
        Resumen: worksheetResumen,
        ...this.arrPropuesta.reduce((acc, element) => {
          const arr = [] as any;
          element.solicitudes.forEach((itemSol, index) => {
            itemSol.valores.forEach(valores => {
              const objSol = {
                'N°': itemSol.countRequest.toString().padStart(4, '0'),
                Proveedor: itemSol.nombreProveedor,
                Concepto: valores.concepto,
                'Partida Presupuestal': valores.partidaPres,
                'Fecha Propuesta': new Date(
                  itemSol.fechaPropuesta.seconds * 1000
                ),
                Importe: element.importes[index].importe,
                IVA: element.importes[index].iva || 0,
                'Otras Cont': element.importes[index].otrasCont || 0,
                'Ret IVA': element.importes[index].retIva || 0,
                'Ret ISR': element.importes[index].retIsr || 0,
                Total: element.importes[index].total,
                Folio: valores.folio,
              };
              arr.push(objSol);
            });
          });
          arr.push({
            'N°': '',
            Proveedor: '',
            Concepto: '',
            'Partida Presupuestal': '',
            'Fecha Propuesta': '',
            Importe: element.totales.importe,
            IVA: element.totales.iva || 0,
            'Otras Cont': element.totales.otrasCont || 0,
            'Ret IVA': element.totales.retIVA || 0,
            'Ret ISR': element.totales.retISR || 0,
            Total: element.totales.total,
            Folio: '',
          });

          acc[element.tipoPago] = XLSX.utils.json_to_sheet(arr);
          return acc;
        }, {}),
      },
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, 'Propuesta de pagos');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  ngOnDestroy() {
    this.getPurchaseOrderSubscription.unsubscribe();
  }
}
