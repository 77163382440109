import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { PurchaseOrder1Component } from '../purchase-order1/purchase-order1.component';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Notiflix from 'notiflix-angular';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

declare var $;
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-consult-po1',
  templateUrl: './consult-po1.component.html',
  styleUrls: ['./consult-po1.component.css'],
})
export class ConsultPO1Component implements OnInit {
  @ViewChild(PurchaseOrder1Component) purchaseOrder: PurchaseOrder1Component;
  @ViewChild('selectFieldsModal') selectFieldsModal: TemplateRef<any>;

  idCompany: string;
  idProject: string;
  arrPurchaseOrder = [] as any;
  arrTable = [] as any;
  po = {
    comprometidos: [] as any,
    cuentas: [] as any,
  } as PurchaseOrder;
  conCalendario: string;
  filterProviders = [];
  objPo;
  pagado = 0;
  dataTable = [];
  filterSolicitudes = [] as any;
  dates = {} as any;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;

  constructor(
    private dealMemoService: DealMemoService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);

    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;

    this.getPurchaseOrder();
  }

  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);

        this.arrPurchaseOrder = res.sort((a, b) => {
          return a.orderCounter - b.orderCounter;
        });

        this.arrPurchaseOrder.forEach(element => {
          element.expanded = false;
        });
        // this.filterProviders = this.arrPurchaseOrder;

        // console.log(this.arrPurchaseOrder);
        this.tableData();
      });
  }

  editarComentario(item: any): void {
    // Activar el modo de edición y copiar el comentario actual
    item.editando = true;
    item.nuevoComentario = item.comentario || '';
  }

  // Campos a exportar excel
  fields = [
    { name: 'N°', key: 'orderCounter' },
    { name: 'Partida Presupuestal', key: 'cuentas' },
    { name: 'Proveedor', key: 'nombreProveedor' },
    { name: 'N° Proveedor', key: 'numeroProveedor' },
    { name: 'RFC', key: 'rfc' },
    { name: 'Actividad', key: 'actividad' },
    { name: 'Aprobado por', key: 'aprobadoPor' },
    { name: 'Moneda', key: 'moneda' },
    { name: 'Tipo OC', key: 'tipo' },
    { name: 'Tipo Pago', key: 'tipoPago' },
    { name: 'TC', key: 'TC' },
    { name: 'Valor OC', key: 'total' },
  ];
  downloadExcel() {
    const OC = [];
    const solicitudes = [];

    this.arrPurchaseOrder.forEach(order => {
      const flattenedOrder: any = {};

      // Asignar datos generales de la orden
      this.fields.forEach(field => {
        if (field.key === 'cuentas') {
          flattenedOrder[field.name] = order[field.key].join(', '); // Aplanar las cuentas
        } else {
          flattenedOrder[field.name] = order[field.key];
        }
      });

      // Agrupar los datos comprometidos por periodo
      order.comprometidos.forEach(comprometido => {
        if (comprometido.periodo) {
          const periodo = `${comprometido.importe}`;
          const cuenta = `${comprometido.partidaPres}`;
          flattenedOrder[`Cuenta ${comprometido.periodo}`] = cuenta;
          flattenedOrder[`${comprometido.periodo}`] = periodo;
        }
      });

      OC.push(flattenedOrder);
      // Agregar solicitudes si existen
      if (order.solicitudes && order.solicitudes.length > 0) {
        order.solicitudes.forEach(solicitud => {
          const solicitudData = {
            'N° OC': order.orderCounter,
            Proveedor: order.nombreProveedor,
            RFC: order.rfc,
            'Partida Presupuestal': order.cuentas.join(', '),
            Actividad: order.actividad,
            Moneda: order.moneda,
            TC: order.TC,
            'Valor OC': order.total,
            'N° SOL': solicitud.countRequest,
            Concepto: solicitud.concepto,
            Deducible: solicitud.deducible,
            Etapa: solicitud.etapa,
            Periodo: solicitud.periodo,
            'Solicitado Por': solicitud.solicitadoPor,
            Tipo: solicitud.tipo,
            'Moneda ': solicitud.moneda,
            'TC ': solicitud.TC,
          };
          solicitudes.push(solicitudData);
        });
      }
    });

    // console.log('Datos comprometidos por periodo:', OC);
    // console.log('Solicitudes:', solicitudes);

    // Crear y exportar el archivo Excel
    const worksheetOC: XLSX.WorkSheet = XLSX.utils.json_to_sheet(OC);
    const worksheetSolicitudes: XLSX.WorkSheet =
      XLSX.utils.json_to_sheet(solicitudes);
    const workbook: XLSX.WorkBook = {
      Sheets: { OC: worksheetOC, Solicitudes: worksheetSolicitudes },
      SheetNames: ['OC', 'Solicitudes'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, 'PurchaseOrders');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  filterOrders(event: any) {
    const text = event.target.value.toUpperCase();
    this.arrTable = this.arrPurchaseOrder.filter(order => {
      const nombreProveedor = order.nombreProveedor
        ? order.nombreProveedor.toUpperCase()
        : '';
      const rfc = order.rfc ? order.rfc.toUpperCase() : '';
      const actividad = order.actividad ? order.actividad.toUpperCase() : '';

      return (
        nombreProveedor.includes(text) ||
        rfc.includes(text) ||
        actividad.includes(text)
      );
    });
    // console.log(this.arrTable);
  }
  guardarComentario(item: any): void {
    const index = this.arrPurchaseOrder.findIndex(
      order => order.orderCounter === item.orderCounter
    );

    if (index !== -1) {
      // Si no hay comentario previo, agrega el nuevo
      this.arrPurchaseOrder[index].comentario = item.nuevoComentario || '';

      // Desactiva el modo de edición
      item.editando = false;
      delete item.nuevoComentario;

      // Actualizar el backend
      this.dealMemoService.updatePO(
        this.idCompany,
        this.idProject,
        item.id,
        this.arrPurchaseOrder[index]
      );

      // console.log('Comentario guardado:', this.arrPurchaseOrder[index]);
    } else {
      console.error('Orden no encontrada.');
    }
  }

  cancelarOC(item: any): void {
    if (item.cancelada) {
      Notiflix.Notify.Warning(
        `La Orden de Compra ${item.orderCounter} ya ha sido cancelada.`
      );
    } else {
      Notiflix.Confirm.Show(
        'ALERTA',
        '¿Deseas CANCELAR la Orden de Compra ' +
          'N° ' +
          item.orderCounter +
          '?',
        'Si',
        'No',
        () => {
          item.cancelada = true;
          // Actualizar el backend
          this.dealMemoService.updatePO(
            this.idCompany,
            this.idProject,
            item.id,
            item
          );
          Notiflix.Notify.Success(
            `Orden de compra ${item.orderCounter} cancelada correctamente.`
          );
          // console.log(item);
        }
      );
    }
  }

  cancelarEdicion(item: any): void {
    // Salir del modo de edición y limpiar el campo temporal
    item.editando = false;
    delete item.nuevoComentario;
  }

  // realizar suma solicitudes de la orden
  tableData() {
    this.arrTable = this.arrPurchaseOrder;

    this.filterSolicitudes = [];
    this.arrTable.forEach(element => {
      // console.log(element);

      element.solicitudes
        .filter(elementSol => elementSol.pagado === true)
        .forEach(elementSol => {
          this.filterSolicitudes.push(elementSol);

          elementSol.valores.forEach(elementValores => {
            if (element.cuentas[0] === elementValores.partidaPres) {
              element.pagado = elementValores.total;
            }
          });
        });
      let sumaValorOC = 0;
      element.comprometidos.forEach(elementComp => {
        sumaValorOC += elementComp.importe * elementComp.cantidad;
      });
      element.total = sumaValorOC;
    });

    // console.log(this.arrTable);
  }

  ordenEdit(obj) {
    $('#editPurchaseOrder').modal('show');
    // console.log('ORDEN SELECCIONADA', obj);

    this.purchaseOrder.po = {} as any;
    this.purchaseOrder.po = obj;
    this.purchaseOrder.filmadoraSeleccionada = obj.filmadora.name;
    this.purchaseOrder.total();
    this.objPo = this.clonar(obj);
    // console.log(this.objPo);
  }

  clonar(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  updatePo() {
    this.purchaseOrder.editOrder(this.objPo);
    $('#editPurchaseOrder').modal('hide');
  }
}
