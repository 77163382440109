import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';

import Notiflix from 'notiflix-angular';
import { EmpresasService } from 'src/app/services/empresas.service';
import { GeneralService } from 'src/app/services/general.service';

declare var $: any;
declare var bootstrap: any;

@Component({
  selector: 'app-purchase-order1',
  templateUrl: './purchase-order1.component.html',
  styleUrls: ['./purchase-order1.component.css'],
})
export class PurchaseOrder1Component implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;
  po = {
    comprometidos: [] as any,
    cuentas: [] as any,
    calendario: true,
    tipo: 'Directa',
  } as PurchaseOrder;
  pago = {} as any;
  providers = [] as any;
  proveedorSeleccionado = '';
  objProvider = {} as any;
  fechas = {} as any;
  countDealMemo = {} as any;
  budget = [] as any;
  partidasIva = [] as any;
  etapas = [] as any;
  periodos = [] as any;
  user: string;
  project = {} as any;
  acumuladoTemporal = 0;
  disponible = 0;
  importeDePartida = 0;
  activarDisponible = false;
  bitacora = [] as any;
  isEdit: any;
  arrCategoria = [] as any;
  cuentas = [] as any;
  subcuentas = [] as any;
  detalle = [] as any;
  budgetCompleto = [] as any;
  filmadoras = [] as any;
  filmadoraSeleccionada = {} as any;
  company = [] as any;
  rfcReceptor = [] as any;
  totales = {} as any;
  banco = {} as any;
  nacionalidadExt: boolean;

  // Subscriptions
  getProviderSubscription: Subscription;
  subscribeCountDealMemo: Subscription;
  subscriberBudget: Subscription;
  subscribePeriods: Subscription;
  subscriberGetUser: Subscription;
  subscriberGetProject: Subscription;
  findOrderSubscription: Subscription;
  getCompanySubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private dealMemoService: DealMemoService,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private authService: AuthService,
    private generalService: GeneralService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isEdit = url.root.children.primary.segments[4].path;
    this.getCount();
    this.getProvider();
    this.getBudget();
    this.getPeriods();
    this.getUser();
    this.getCompany();
    this.getProject();
  }

  getCompany() {
    this.getCompanySubscription = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        const objFilmadora = {
          name: res.name,
          rfc: res.rfc,
        };
        this.filmadoras.push(objFilmadora);
        this.company = res;
        this.rfcReceptor = res.rfc.toUpperCase();
        this.getProject();
      });
  }

  getCount() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getCountDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countDealMemo.countDealMemo = 1;
          this.po.orderCounter = this.countDealMemo.countDealMemo;
        } else {
          this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
          this.po.orderCounter = this.countDealMemo.countDealMemo;
        }
      });
  }

  changeType() {
    this.deleteProvider();
  }

  getProvider() {
    this.getProviderSubscription = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  changeSelectProvider() {
    const segmentos = this.proveedorSeleccionado.split(' - ');
    const numeroProveedor = parseInt(segmentos[0]);

    const proveedor = this.providers.find(
      provider => provider.numero === numeroProveedor
    );

    if (proveedor !== undefined) {
      this.objProvider = proveedor;
      this.po.numeroProveedor = proveedor.numero;
      this.po.nombreProveedor = proveedor.nombre;
      this.po.rfc = proveedor.rfc;
      this.po.proveedor = [] as any;
      this.po.proveedor.push(proveedor);
      this.valNacionalidad();
    }
  }

  deleteProvider() {
    delete this.po.numeroProveedor;
    delete this.po.nombreProveedor;
    delete this.po.rfc;
    delete this.po.proveedor;
    this.proveedorSeleccionado = '';
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta función desglosa todo el presupuesto
          this.budgetCompleto = arrBudget; // Este es el array completo
          //console.log(budget); // este es el array que contiene hijos (children)
          let arrFilter = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 3
          );
          this.budget = arrFilter; // Este es el array que mostramos en la lista
          const arrFilterCat = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 1
          );
          this.arrCategoria = arrFilterCat;
        }
      });
  }

  selectCategoria() {
    console.log(this.pago.cuenta);
    // Buscar todas las cuentas que pertenecen a la categoria seleccionada
    console.log(this.budgetCompleto);
    this.subcuentas = this.budgetCompleto.filter(obj => {
      if (
        obj.CodigoAgrupador.split('-')[0] === this.pago.cuenta &&
        obj.CodigoAgrupador.split('-').length === 2
      ) {
        obj.CodigoAgrupador = obj.CodigoAgrupador.split('-')[1];
        return obj;
      }
    });
    console.log(this.subcuentas);
  }

  selectSubcuenta() {
    console.log(this.pago.subcuenta);
    // Buscar todas las cuentas que pertenecen a la subcuenta seleccionada
    this.detalle = this.budgetCompleto.filter(obj => {
      if (
        obj.CodigoAgrupador.split('-')[0] === this.pago.cuenta &&
        obj.CodigoAgrupador.split('-')[1] === this.pago.subcuenta &&
        obj.CodigoAgrupador.split('-').length === 3
      ) {
        // obj.CodigoAgrupador = obj.CodigoAgrupador.split('-')[2];
        return obj;
      }
    });
    console.log(this.detalle);
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(Object.assign([], res));
        this.periodos = Object.assign([], res);
        const periods = Object.assign([], res);
        const result = periods.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(t => {
              return t.etapa === obj.etapa;
            })
          );
        });
        this.etapas = result;
      });
  }

  selectPeriod() {
    console.log(this.pago.periodo);
    const obj = this.periodos.find(
      element => element.nombrePeriodo === this.pago.periodo
    );
    console.log(obj);
    this.fechas.fechaInicio = obj.fechaInicio;
    this.fechas.fechaFin = obj.fechaFin;
  }

  getUser() {
    this.subscriberGetUser = this.authService.userData$.subscribe(res => {
      this.user = res.email;
    });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        // console.log(res);
        this.project = res;
        this.po.moneda = res.monedaLocal;
        this.po.TC = res.tipoCambioGral;

        if (res.filmadoras) {
          this.filmadoras = this.filmadoras.concat(res.filmadoras);
        }
        this.po.filmadora = this.filmadoras[0];
      });
  }

  getPago(info) {
    if (info === 'importe' || info === 'cantidad') {
      this.pago.iva = this.pago.importe * 0.16;
      this.pago.total = this.pago.importe + this.pago.iva;
    } else if (info === 'iva') {
      this.pago.total = this.pago.importe + this.pago.iva;
    }
  }

  changeFilmadora() {
    this.filmadoras.forEach(element => {
      if (element.name === this.filmadoraSeleccionada) {
        this.po.filmadora = element;
      }
    });
  }

  valNacionalidad() {
    if (this.objProvider.nacionalidad === 'Extranjera') {
      this.nacionalidadExt = true;
      this.objProvider.rfc = 'XEXX010101000';
    } else {
      this.nacionalidadExt = false;
    }
  }

  addBank() {
    if (
      this.banco.nombreBanco &&
      this.banco.cuenta &&
      (this.banco.clabe || this.banco.codigoSwift)
    ) {
      if (this.po.bancos) {
        this.po.bancos.push(this.banco);
      } else {
        this.po.bancos = [];
        this.po.bancos.push(this.banco);
      }
      this.banco = {} as any;
    } else {
      Notiflix.Notify.Failure('Completa los campos');
    }
  }
  deleteBank(i) {
    this.po.bancos.splice(i, 1);
  }

  editOrder(obj) {
    console.log(obj);
    // console.log(this.po);
    if (obj.historico) {
      delete obj.historico;
    }

    console.log(this.po.historico);
    console.log(this.po);

    if (this.po.historico) {
      this.po.historico = [];

      this.po.historico.push(obj);
      this.dealMemoService
        .updatePO(this.idCompany, this.idProject, this.po.id, this.po)
        .then(res => {
          Notiflix.Notify.Success('Se actualizo correctamente ');
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Se Actualizo la orden #${this.po.orderCounter}`,
            user: this.user,
          });
        });
      // console.log(this.po.historico, 'se crea el historico');
    } else {
      this.po.historico = [] as any;
      this.po.historico.push(obj);
      this.dealMemoService
        .updatePO(this.idCompany, this.idProject, this.po.id, this.po)
        .then(res => {
          Notiflix.Notify.Success('Se actualizo correctamente ');
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Se Actualizo la orden #${this.po.orderCounter}`,
            user: this.user,
          });
        });
      // console.log(this.po.historico, 'cuenta con historico');
    }
  }

  editValues(i) {
    $('#modelDeleteValue').modal('show');

    Notiflix.Confirm.Init({
      titleColor: '#FF0000',
      okButtonBackground: '#FF0000',
    });
    Notiflix.Confirm.Show(
      'Eliminar cuenta',
      '¿Deseas eliminar la cuenta?',
      'Si',
      'No',
      () => {
        this.po.comprometidos.splice(i, 1);
        const uniqueCuentasSet = new Set<string>();
        this.po.comprometidos.forEach(element => {
          uniqueCuentasSet.add(element.partidaPres);
        });
        this.po.cuentas = Array.from(uniqueCuentasSet);
        this.total();
      },
      () => {}
    );
  }

  editCta() {
    $('#modelDeleteValue').modal('hide');
  }

  selectCtaIva() {
    this.partidasIva = [
      {
        CodigoAgrupador: '0150',
        Descripcion: 'IVA',
      },
    ] as any;
    const objIva = this.budget.find(
      element =>
        element.CodigoAgrupador.split('-')[0] ===
          this.pago.partidaPres.split('-')[0] &&
        element.Descripcion === 'Total Fringes'
    );
    // console.log(objIva);
    if (objIva) {
      this.pago.partidaPresIva = objIva.CodigoAgrupador;
      this.partidasIva.push(objIva);
    } else {
      // this.pago.partidaPresIva = this.pago.partidaPres;
      this.partidasIva.push({
        CodigoAgrupador: this.pago.partidaPres,
        Descripcion: 'IVA',
      });
    }
  }

  modalAddExtra: any;
  openAddExtra() {
    this.modalAddExtra = new bootstrap.Modal(
      <any>document.getElementById('modalAddExtra'),
      {}
    );
    this.modalAddExtra.show();
  }

  addExtra() {
    console.log(this.pago);
    if (this.pago.comentario && this.pago.motivo) {
      this.modalAddExtra.hide();
      this.addPago2();
    } else {
      Notiflix.Notify.Failure('Selecciona un motivo y agrega un comentario');
    }
  }

  addPago2() {
    console.log(this.pago);

    // Sumar los importes de la tabla que tengan la misma partida presupuestal
    if (
      this.pago.partidaPres &&
      this.pago.partidaPresIva &&
      this.pago.importe &&
      this.pago.cantidad
    ) {
      let sumaTabla = 0;
      this.po.comprometidos.forEach(element => {
        if (element.partidaPres === this.pago.partidaPres) {
          sumaTabla += element.importe;
        }
      });

      // buscar ordenes con la misma partida presupuestal
      this.findOrderSubscription = this.dealMemoService
        .findOrders(this.idCompany, this.idProject, this.pago.partidaPres)
        .subscribe(res => {
          console.log(res);
          this.acumuladoTemporal = 0;
          this.disponible = 0;

          // Si encuentra orden de compra a la misma partida presupuestal suma el acumulado
          if (res.length > 0) {
            res.forEach(elementOrder => {
              elementOrder.comprometidos.forEach(elementComprometidos => {
                if (
                  elementComprometidos.partidaPres === this.pago.partidaPres
                ) {
                  let comprometido = 0;
                  if (elementOrder.moneda === this.project.monedaLocal) {
                    comprometido =
                      elementComprometidos.importe *
                      elementComprometidos.cantidad;
                  } else if (this.project.monedaLocal === 'MXN') {
                    comprometido =
                      elementComprometidos.importe *
                      elementOrder.TC *
                      elementComprometidos.cantidad;
                  } else if (this.project.monedaLocal === 'USD') {
                    comprometido =
                      (elementComprometidos.importe / elementOrder.TC) *
                      elementComprometidos.cantidad;
                  }
                  this.acumuladoTemporal += comprometido;
                }
              });
            });
          }

          this.acumuladoTemporal = parseFloat(
            this.acumuladoTemporal.toFixed(3)
          );

          // Buscar la partida presupuestal seleccionada en el presupuesto
          const objPres = this.budget.find(
            element => element.CodigoAgrupador === this.pago.partidaPres
          );
          this.pago.partidaDes = objPres.Descripcion;

          this.disponible =
            (this.po.moneda === this.project.monedaLocal
              ? objPres.Importe_Estimado
              : objPres.Importe_Estimado * this.po.TC) -
            (this.po.moneda === this.project.monedaLocal
              ? this.acumuladoTemporal
              : this.acumuladoTemporal * this.po.TC);

          console.log(
            'Presupuesto: ' +
              objPres.Importe_Estimado +
              ` ${this.project.monedaLocal}`
          );

          console.log(
            'Acumulado de otras OC: ',
            this.po.moneda === this.project.monedaLocal
              ? this.acumuladoTemporal
              : this.acumuladoTemporal * this.po.TC
          );

          console.log('Disponible total: ', this.disponible);

          console.log('Acumulado en esta OC: ', sumaTabla);
          console.log(
            'Disponible menos lo agregado: ',
            this.disponible - sumaTabla - this.pago.importe * this.pago.cantidad
          );

          // Convierto las fechas a formato Date y las agrego al objeto
          if (this.po.calendario) {
            this.pago.fechaInicio = new Date(
              this.fechas.fechaInicio + 'T00:00:00'
            );
            this.pago.fechaFin = new Date(this.fechas.fechaFin + 'T11:59:59');
          }

          // Si el importe agregado no excede el disponible se agrega a la tabla
          if (
            this.disponible -
              sumaTabla -
              this.pago.importe * this.pago.cantidad >=
            0
          ) {
            const indexPeriodo = this.periodos.findIndex(
              element => element.nombrePeriodo === this.pago.periodo
            );

            let cantidad = this.pago.cantidad;
            for (let index = 0; index < this.pago.cantidad; index++) {
              const newPago = JSON.parse(JSON.stringify(this.pago));
              newPago.cantidad = 1;
              newPago.importe =
                newPago.importe * (cantidad >= 1 ? 1 : cantidad);
              newPago.iva = newPago.iva * (cantidad >= 1 ? 1 : cantidad);
              cantidad -= 1;
              newPago.total = newPago.importe + newPago.iva;

              if (this.po.calendario) {
                if (indexPeriodo + index <= this.periodos.length - 1) {
                  newPago.periodo =
                    this.periodos[indexPeriodo + index].nombrePeriodo;
                  newPago.fechaInicio =
                    this.periodos[indexPeriodo + index].fechaInicio;
                  newPago.fechaFin =
                    this.periodos[indexPeriodo + index].fechaFin;
                } else {
                  Notiflix.Notify.Failure('No hay más periodos');
                  break;
                }
              }
              this.po.comprometidos.push(newPago);
            }
            const partidaPresExistente = this.po.cuentas.find(
              cuenta => cuenta === this.pago.partidaPres
            );
            if (!partidaPresExistente) {
              this.po.cuentas.push(this.pago.partidaPres);
            }
            this.total();
            this.pago = {} as any;
            this.partidasIva = [] as any;
            this.fechas = {} as any;
          } else {
            Notiflix.Notify.Failure(
              'El importe excede el disponible del presupuesto'
            );
            Notiflix.Confirm.Show(
              'El valor excede el disponible',
              '¿Deseas agregarla?',
              'Si',
              'No',
              () => {
                $('#modalExcedido').modal('show');
              },
              () => {}
            );
          }
          this.findOrderSubscription.unsubscribe();
        });
    } else {
      Notiflix.Notify.Failure(
        'No olvides seleccionar la partida presupuestal, agregar una cantidad e importe'
      );
    }
  }

  total() {
    this.totales = {
      cantidad: 0,
      importe: 0,
      iva: 0,
      total: 0,
    };
    this.po.comprometidos.forEach(comprometido => {
      this.totales.cantidad += comprometido.cantidad;
      this.totales.importe += comprometido.importe;
      this.totales.iva += comprometido.iva;
      this.totales.total += comprometido.total;
    });
  }

  excedido() {
    this.pago.alerta = 'Excedido';

    const indexPeriodo = this.periodos.findIndex(
      element => element.nombrePeriodo === this.pago.periodo
    );
    for (let index = 0; index < this.pago.cantidad; index++) {
      const newPago = JSON.parse(JSON.stringify(this.pago));
      newPago.cantidad = 1;

      if (this.po.calendario) {
        if (indexPeriodo + index <= this.periodos.length - 1) {
          newPago.periodo = this.periodos[indexPeriodo + index].nombrePeriodo;
          newPago.fechaInicio = this.periodos[indexPeriodo + index].fechaInicio;
          newPago.fechaFin = this.periodos[indexPeriodo + index].fechaFin;
        } else {
          Notiflix.Notify.Failure('No hay más periodos');
          break;
        }
      }
      this.po.comprometidos.push(newPago);
    }

    this.po.cuentas.push(this.pago.partidaPres);
    this.pago = { cuentas: [] as any } as any;
    this.fechas = {} as any;
    $('#modalExcedido').modal('hide');
  }

  savePo() {
    if (
      (this.po.actividad.trim().length > 0,
      this.po.departamento.trim().length > 0,
      this.po.aprobadoPor.trim().length > 0,
      this.po.moneda.trim().length > 0,
      this.po.comprometidos.length > 0,
      this.po.TC > 0)
    ) {
      this.po.fechaCreado = new Date();
      this.po.creadoPor = this.user;
      this.po.solicitudes = [];
      this.po.xml = [];
      console.log(this.po);

      this.dealMemoService
        .addPurchaseOrder(this.idCompany, this.idProject, this.po)
        .then(() => {
          Notiflix.Notify.Success('¡Guardado Exitoso!');
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Se creo la orden #${this.po.orderCounter}`,
            user: this.user,
          });
          this.dealMemoService.countDealMemo(
            this.idCompany,
            this.idProject,
            this.countDealMemo
          );
          this.po = {
            comprometidos: [] as any,
            moneda: this.project.monedaLocal,
            TC: this.project.tipoCambioGral,
            cuentas: [] as any,
            calendario: true,
            filmadora: this.po.filmadora,
          } as PurchaseOrder;
          this.proveedorSeleccionado = '';
          this.pago = {} as any;
        })
        .catch(err => {
          console.error(err);
          Notiflix.Notify.Failure(
            'Ocurrió un error al guardar la Orden de compra'
          );
          this.generalService.updateBit(this.idCompany, this.idProject, {
            fecha: new Date(),
            mensaje: `Ocurrio un error al guardar la orden # ${this.po.orderCounter}`,
            user: this.user,
          });
        });
    }
  }

  ngOnDestroy(): void {
    this.getProviderSubscription.unsubscribe();
    this.subscribeCountDealMemo.unsubscribe();
    this.subscriberBudget.unsubscribe();
    this.subscribePeriods.unsubscribe();
    this.subscriberGetUser.unsubscribe();
    this.subscriberGetProject.unsubscribe();
    this.getCompanySubscription.unsubscribe();
  }
}
