import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Disponible } from 'src/app/models/costReport';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { EmpresasService } from 'src/app/services/empresas.service';

@Component({
  selector: 'app-available',
  templateUrl: './available.component.html',
  styleUrls: ['./available.component.css'],
})
export class AvailableComponent implements OnInit {
  idCompany: string;
  idProject: string;

  getBudget: any;
  budgetArrays = [] as any;
  arrPurchaseOrder = [] as any;
  filterOrders = [] as any;
  dataTable = [];
  dates = {} as Disponible;
  monedaSeleccionada;
  filterSolicitudes = [] as any;
  project = {} as any;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;
  subscriberGetBudget: Subscription;
  subscriberProjectActual: Subscription;

  constructor(
    private router: Router,
    private budgetService: BudgetService,
    private dealMemoService: DealMemoService,
    private empresasService: EmpresasService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getPurchaseOrder();
    this.getBudgets();
    this.getProject();
  }

  getProject() {
    this.subscriberProjectActual = this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        // console.log(this.project);

        this.monedaSeleccionada = this.project.monedaLocal;
        // console.log(this.monedaSeleccionada);
      });
  }

  getBudgets() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.getBudget = arrBudget; // Este es el array que mostramos en la tabla
        }
        // console.log(this.getBudget);
        // this.reporteDisponible();
      });
  }
  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);

        this.arrPurchaseOrder = res.sort((a, b) => {
          return a.orderCounter - b.orderCounter;
        });
        // console.log(this.arrPurchaseOrder);
      });
  }

  changeMoneda() {
    // console.log(this.filterOrders);
    // console.log(this.monedaSeleccionada);

    this.reportForDate();
  }

  // REPORTE POR FECHA
  reportForDate() {
    // console.log(this.monedaSeleccionada);
    this.dataTable = [];
    this.filterOrders = [];
    this.filterSolicitudes = [];
    let obj = {} as any;

    // Convertir fechaInicio y fechaFin a milisegundos
    const fechaInic = new Date(this.dates.fechaIni + 'T00:00:00');
    const fechaInicio = fechaInic.getTime(); // Milisegundos desde la fecha de inicio

    const fechaFinal = new Date(this.dates.fechaFin + 'T23:59:59');

    const fechaFin = fechaFinal.getTime(); // Milisegundos hasta la fecha final
    // console.log('Fecha Fin:', new Date(fechaFin)); // Solo para verificar

    // Filtrarmos las órdenes de compra por rango de fechas  si la fecha de creación
    // de la orden está dentro del rango de fechas se agregará al array filterOrders
    this.arrPurchaseOrder.forEach(elementOrders => {
      const seconds = elementOrders.fechaCreado.seconds;
      const fechaCreado = new Date(seconds * 1000).getTime(); // Convertir fechaCreado a milisegundos

      // Comprobar si fechaCreado está dentro del rango de fechas seleccionado y agregar al array
      if (fechaCreado > fechaInicio && fechaCreado < fechaFin) {
        this.filterOrders.push(elementOrders);
      }
    });
    // console.log(this.filterOrders, 'ORDENES');

    // Filtrar las solicitudes de pago por rango de fechas solo si están pagadas y
    // agregar al array filterSolicitudes
    for (let index = 0; index < this.filterOrders.length; index++) {
      const element = this.filterOrders[index];
      element.solicitudes.forEach(elementSol => {
        const secondsSol = elementSol.fechaSol.seconds;
        const fechaSolCreate = new Date(secondsSol * 1000).getTime();
        if (fechaSolCreate > fechaInicio && fechaSolCreate < fechaFin) {
          if (elementSol.pagado === true) {
            // console.log(elementSol);
            this.filterSolicitudes.push(elementSol);
          }
        }
      });
    }
    console.log(
      this.filterSolicitudes,
      'SOLICITUDES Pagadas en el rango elegido de fechas'
    );

    //  inicia funcion para el reporte disponible
    this.getBudget.forEach(elementBugget => {
      const segmentos = elementBugget.CodigoAgrupador.split('-');
      obj.CodigoAgrupador = elementBugget.CodigoAgrupador;
      obj.descripcion = elementBugget.Descripcion;

      // En este punto se asigna el presupuesto inicial dependiendo de la moneda seleccionada y
      // por nivel se asigna un color
      if (this.project.monedaLocal === this.monedaSeleccionada) {
        if (elementBugget.nivel === 1) {
          obj.presupuestoInicial = elementBugget.Total_Estimado;
          obj.color = 'azul';
          obj.nivel = 1;
        } else if (elementBugget.nivel === 2) {
          obj.presupuestoInicial = elementBugget.Subtotal_Estimado;
          obj.color = 'amarillo';
          obj.nivel = 2;
        } else if (elementBugget.nivel === 3) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.color = 'treeSegmentsWhitChild';
          obj.nivel = 3;
        } else if (elementBugget.nivel === 3.5) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.color = 'verde';
          obj.nivel = 3.5;
        } else if (elementBugget.nivel === 4) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.nivel = 4;
        }
      } else if (
        this.project.monedaLocal === 'USD' &&
        this.monedaSeleccionada === 'MXN'
      ) {
        if (elementBugget.nivel === 1) {
          obj.presupuestoInicial =
            elementBugget.Total_Estimado * this.project.tipoCambioGral;
          obj.color = 'azul';
          obj.nivel = 1;
        } else if (elementBugget.nivel === 2) {
          obj.presupuestoInicial =
            elementBugget.Subtotal_Estimado * this.project.tipoCambioGral;
          obj.color = 'amarillo';
          obj.nivel = 2;
        } else if (elementBugget.nivel === 3) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado * this.project.tipoCambioGral;
          obj.color = 'treeSegmentsWhitChild';
          obj.nivel = 3;
        } else if (elementBugget.nivel === 3.5) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.color = 'verde';
          obj.nivel = 3.5;
        } else if (elementBugget.nivel === 4) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado * this.project.tipoCambioGral;
          obj.nivel = 4;
        }
      } else if (
        this.project.monedaLocal === 'MXN' &&
        this.monedaSeleccionada === 'USD'
      ) {
        if (elementBugget.nivel === 1) {
          obj.presupuestoInicial =
            elementBugget.Total_Estimado / this.project.tipoCambioGral;
          obj.color = 'azul';
          obj.nivel = 1;
        } else if (elementBugget.nivel === 2) {
          obj.presupuestoInicial =
            elementBugget.Subtotal_Estimado / this.project.tipoCambioGral;
          obj.color = 'amarillo';
          obj.nivel = 2;
        } else if (elementBugget.nivel === 3) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado / this.project.tipoCambioGral;
          obj.color = 'treeSegmentsWhitChild';
          obj.nivel = 3;
        } else if (elementBugget.nivel === 3.5) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.color = 'verde';
          obj.nivel = 3.5;
        } else if (elementBugget.nivel === 4) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado / this.project.tipoCambioGral;

          obj.nivel = 4;
        }
      }

      // SUMA Solicitudes Pagadas
      // Aquí se suman las solicitudes pagadas por cada partida presupuestal
      let sumaSolicitudes = 0;

      this.filterSolicitudes.forEach(elementSolicitudes => {
        if (
          elementSolicitudes.moneda === 'USD' &&
          this.project.monedaLocal === 'USD'
        ) {
          elementSolicitudes.valores.forEach(elementValores => {
            if (elementBugget.CodigoAgrupador === elementValores.partidaPres) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaSolicitudes +=
                  elementValores.importe * elementSolicitudes.TC;
              } else {
                sumaSolicitudes += elementValores.importe;
              }
            }
          });
        } else if (
          elementSolicitudes.moneda === 'MXN' &&
          this.project.monedaLocal === 'MXN'
        ) {
          elementSolicitudes.valores.forEach(elementValores => {
            if (elementBugget.CodigoAgrupador === elementValores.partidaPres) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaSolicitudes += elementValores.importe;
              } else {
                sumaSolicitudes +=
                  elementValores.importe / elementSolicitudes.TC;
              }
            }
          });
        } else if (
          elementSolicitudes.moneda === 'MXN' &&
          this.project.monedaLocal === 'USD'
        ) {
          elementSolicitudes.valores.forEach(elementValores => {
            if (elementBugget.CodigoAgrupador === elementValores.partidaPres) {
              if (this.monedaSeleccionada === 'USD') {
                sumaSolicitudes +=
                  elementValores.importe / elementSolicitudes.TC;
              } else {
                sumaSolicitudes +=
                  elementValores.importe * elementSolicitudes.TC;
              }
            }
          });
        }
      });
      //

      // SUMA COMPROMETIDOS
      // Aquí se suman los comprometidos por cada partida presupuestal
      let sumaComprometidos = 0;
      for (let index = 0; index < this.filterOrders.length; index++) {
        const element = this.filterOrders[index];
        if (element.moneda === 'USD' && this.project.monedaLocal === 'USD') {
          element.comprometidos.forEach(elementComprometidos => {
            if (
              elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
            ) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaComprometidos += elementComprometidos.importe * element.TC;
              } else {
                sumaComprometidos += elementComprometidos.importe;
              }
            }
          });
        } else if (
          element.moneda === 'MXN' &&
          this.project.monedaLocal === 'USD'
        ) {
          element.comprometidos.forEach(elementComprometidos => {
            if (
              elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
            ) {
              if (this.monedaSeleccionada === 'USD') {
                sumaComprometidos += elementComprometidos.importe / element.TC;
              } else {
                sumaComprometidos += elementComprometidos.importe * element.TC;
              }
            }
          });
        } else if (
          element.moneda === 'MXN' &&
          this.project.monedaLocal === 'MXN'
        ) {
          element.comprometidos.forEach(elementComprometidos => {
            if (
              elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
            ) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaComprometidos += elementComprometidos.importe;
              } else if (this.monedaSeleccionada === 'USD') {
                sumaComprometidos += elementComprometidos.importe / element.TC;
              }
            }
          });
        } else if (
          element.moneda === 'USD' &&
          this.project.monedaLocal === 'MXN'
        ) {
          element.comprometidos.forEach(elementComprometidos => {
            if (
              elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
            ) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaComprometidos += elementComprometidos.importe / element.TC;
              } else if (this.monedaSeleccionada === 'USD') {
                sumaComprometidos += elementComprometidos.importe;
              }
            }
          });
        }
      }
      // agregamos los valores a la tabla de reporte en dataTable
      obj.pagado = sumaSolicitudes;
      obj.comprometidos = sumaComprometidos;
      obj.actualComprometido = obj.comprometidos + obj.pagado;
      obj.disponible =
        obj.presupuestoInicial - obj.actualComprometido < 0
          ? 0
          : obj.presupuestoInicial - obj.actualComprometido;
      obj.estimadoCierre = obj.actualComprometido + obj.disponible;
      obj.variante = obj.presupuestoInicial - obj.estimadoCierre;

      this.dataTable.push(obj);
      obj = {};
    });

    // Agregar sumas totales para niveles 1 y 2
    this.dataTable.forEach(parent => {
      if (parent.nivel === 1 || parent.nivel === 2) {
        parent.pagado = this.dataTable
          .filter(child =>
            child.CodigoAgrupador.startsWith(parent.CodigoAgrupador + '-')
          )
          .reduce((acc, child) => acc + (child.pagado || 0), 0);

        parent.comprometidos = this.dataTable
          .filter(child =>
            child.CodigoAgrupador.startsWith(parent.CodigoAgrupador + '-')
          )
          .reduce((acc, child) => acc + (child.comprometidos || 0), 0);

        parent.actualComprometido = parent.pagado + parent.comprometidos;
        parent.disponible =
          parent.presupuestoInicial - parent.actualComprometido < 0
            ? 0
            : parent.presupuestoInicial - parent.actualComprometido;
      }
    });
  }
}
