import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BudgetService } from 'src/app/services/budget.service';
import { ProjectService } from 'src/app/services/project.service';
import Notiflix from 'notiflix-angular';

declare var $: any;

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.css'],
})
export class DepartamentosComponent implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;
  budget = [] as any;
  grupo = {} as any;
  grupos = [] as any;

  getBudgetSubsctiption: Subscription;
  getGroupsSubscription: Subscription;

  constructor(
    private budgetService: BudgetService,
    private router: Router,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url).root.children.primary
      .segments;
    this.idCompany = url[1].path;
    this.idProject = url[3].path;
    this.getGroups();
    this.getBudget();
  }

  getGroups() {
    this.getGroupsSubscription = this.projectService
      .getGroups(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          this.grupos = res;
        }
      });
  }

  getBudget() {
    this.getBudgetSubsctiption = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
        }
      });
  }

  selectCta(obj, index) {
    const segments = obj.CodigoAgrupador.split('-');
    if (segments.length === 1) {
      for (let i = index; i < this.budget.length; i++) {
        const item = this.budget[i];
        const segmentosItem = item.CodigoAgrupador.split('-');
        if (segmentosItem[0] === segments[0]) {
          item.seleccionado = obj.seleccionado;
        } else {
          break;
        }
      }
    } else if (segments.length === 2) {
      for (let i = index; i < this.budget.length; i++) {
        const item = this.budget[i];
        const segmentosItem = item.CodigoAgrupador.split('-');
        if (
          segmentosItem[0] === segments[0] &&
          segmentosItem[1] === segments[1]
        ) {
          item.seleccionado = obj.seleccionado;
        } else {
          break;
        }
      }
    }
  }
  openModal() {
    $('#addDepartament').modal('show');
  }

  addGroup() {
    const arrDepartament = new Array();
    for (const item of this.budget) {
      // const segments = item.CodigoAgrupador.split('-');
      if (item.CodigoAgrupador && item.seleccionado === true) {
        const obj = {} as any;
        obj.CodigoAgrupador = item.CodigoAgrupador;
        obj.Descripcion = item.Descripcion;
        obj.Importe_Estimado = item.Importe_Estimado || item.Subtotal_Estimado;
        arrDepartament.push(obj);
      }
    }
    // console.log(arrDepartament);
    this.grupo.cuentas = arrDepartament;
    // console.log(this.grupo);
    if (this.grupo.name && arrDepartament.length > 0) {
      this.projectService
        .saveGroup(this.idCompany, this.idProject, this.grupo)
        .then(() => {
          Notiflix.Notify.Success('Se guardo exitosamente');
          $('#addDepartament').modal('hide');
          this.clean();
        });
    } else {
      if (!this.grupo.name) {
        Notiflix.Notify.Failure('Agrega un nombre');
      } else if (arrDepartament.length === 0) {
        Notiflix.Notify.Failure('Selecciona las cuentas ');
      }
    }
  }

  clean() {
    this.grupo = {};
    this.budget.forEach(element => {
      element.seleccionado = false;
    });
  }

  deleteDepartament(idDepartament) {
    this.projectService
      .deleteGroup(this.idCompany, this.idProject, idDepartament)
      .then(() => {
        Notiflix.Notify.Success('Eliminado exitosamente');
      });
  }

  ngOnDestroy() {
    this.getBudgetSubsctiption.unsubscribe();
    this.getGroupsSubscription.unsubscribe();
  }
}
