import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DealMemoService } from 'src/app/services/deal-memo.service';

@Component({
  selector: 'app-po-report',
  templateUrl: './po-report.component.html',
  styleUrls: ['./po-report.component.css'],
})
export class PoReportComponent implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;
  arrPurchaseOrder = [] as any;
  importes = [] as any;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;

  constructor(
    private router: Router,
    private dealMemoService: DealMemoService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;

    this.getPurchaseOrder();
  }

  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);

        this.arrPurchaseOrder = res.sort((a, b) => {
          return a.orderCounter - b.orderCounter;
        });
        this.arrPurchaseOrder.forEach(element => {
          element.expanded = false;
        });
        console.log(this.arrPurchaseOrder);
        this.generarImportes();
      });
  }

  generarImportes() {
    this.arrPurchaseOrder.forEach(elementOrder => {
      // Comprometido
      let sumaComprometidos = 0;
      elementOrder.comprometidos.forEach(elementComprometido => {
        sumaComprometidos += elementComprometido.total;
        // sacamos el devengado por las fechas de comprometido
        if (elementComprometido.fechaInicio && elementComprometido.fechaFin) {
          const fechaInicioSegundos = elementComprometido.fechaInicio.seconds;
          const fechaInicio = new Date(fechaInicioSegundos * 1000); // Convertir segundos a milisegundos
          const fechaFinSegundos = elementComprometido.fechaFin.seconds;
          const fechaFin = new Date(fechaFinSegundos * 1000); // Convertir segundos a milisegundos

          if (elementOrder.cuentas[0] === elementComprometido.partidaPres) {
            elementOrder.comprometido = elementComprometido.total;
            elementOrder.pendiente =
              elementOrder.comprometido - (elementOrder.pagado || 0);

            const hoy = new Date();
            // solo realizo la comparacion con la fecha fin al dia de hoy para saber que ya esta vencido,
            // falta verificar cual es la fecha que tomaremos en cuenta para realizar la validacion (fecha propuesta pago) ya sea con fecha o sin fecha de comprometidos
            if (fechaFin < hoy) {
              elementOrder.devengado = elementOrder.pendiente;
              elementOrder.color = 'rojo';
            } else {
              elementOrder.devengado = 0;
            }
          }
        }
        // ------------------- //
      });

      // -------------- //

      // Total Acumulado y total pagado
      let sumaSolicitud = 0;
      let sumaSolicitudPagado = 0;
      elementOrder.solicitudes.forEach(elementSolicitudes => {
        if (elementSolicitudes.valores) {
          elementSolicitudes.valores.forEach(elementComprobantes => {
            sumaSolicitud +=
              elementComprobantes.importe + elementComprobantes.iva;
            if (elementSolicitudes.pagado) {
              sumaSolicitudPagado +=
                elementComprobantes.importe + elementComprobantes.iva;
            }
          });
        }
      });
      // ------------------- //

      const obj = {
        totalComprometido: sumaComprometidos,
        totalAcumulado: sumaSolicitud,
        totalPagado: sumaSolicitudPagado,
      };

      this.importes.push(obj);
    });
  }

  ngOnDestroy(): void {
    this.getPurchaseOrderSubscription.unsubscribe();
  }
}
