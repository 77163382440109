import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import Notiflix from 'notiflix-angular';
import { SolGralComponent } from '../sol-gral/sol-gral.component';

declare var $;

@Component({
  selector: 'app-consult-sol',
  templateUrl: './consult-sol.component.html',
  styleUrls: ['./consult-sol.component.css'],
})
export class ConsultSolComponent implements OnInit {
  idCompany: string;

  idProject: string;
  arrSol = [] as any;
  arrOrders = [] as any;

  requestSeleccionada = {} as any;
  solicitudSeleccionada = {} as any;
  orden = [] as any;
  importes = [] as any;
  objSol;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;

  constructor(
    private dealMemoService: DealMemoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);

    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getPurchaseOrder();
  }

  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);
        this.arrOrders = res;
        this.arrSol = [] as any;

        for (let index = 0; index < this.arrOrders.length; index++) {
          const elementOrder = this.arrOrders[index];
          // console.log(elementOrder);
          if (elementOrder.solicitudes.length > 0) {
            elementOrder.solicitudes.forEach(elementRequest => {
              elementRequest.nombreProveedor = elementOrder.nombreProveedor;
              elementRequest.rfc = elementOrder.rfc;
              elementRequest.numeroProveedor = elementOrder.numeroProveedor;
              elementRequest.actividad = elementOrder.actividad;

              elementRequest.provider = elementOrder.proveedor;

              elementRequest.id = elementOrder.id;
              this.arrSol.push(elementRequest);
            });
          }
        }

        this.arrSol.sort((a, b) => {
          return a.countRequest - b.countRequest;
        });
        // console.log(this.arrSol);

        this.arrSol.forEach(elementRequest => {
          let sumaImporte = 0;
          let sumaIva = 0;
          let sumaTotal = 0;
          elementRequest.valores.forEach(elementValores => {
            sumaImporte += elementValores.importe;
            sumaIva += elementValores.iva;
            sumaTotal += elementValores.total;
          });

          const objImportes = {
            importe: sumaImporte,
            iva: sumaIva,
            total: sumaTotal,
          };

          this.importes.push(objImportes);
        });
      });
  }

  solEdit(obj) {
    $('#editSolicitudModal').modal('show'); // Abre el modal correspondiente
    this.solicitudSeleccionada = {} as any; // Limpia la solicitud seleccionada
    this.solicitudSeleccionada = obj; // Llama a la función que asigna la solicitud seleccionada
    console.log(this.solicitudSeleccionada);

    // Formatear la fecha propuesta
    const fecha = new Date(obj.fechaPropuesta.seconds * 1000); // Convertimos segundos a milisegundos
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
    const año = fecha.getFullYear();
    const fechaFormateada = `${año}-${mes}-${dia}`;
    obj.fechaPropuesta = fechaFormateada;
    this.objSol = this.clonar(this.solicitudSeleccionada); // Clona el objeto para evitar mutaciones directas
    // Asignar la solicitud seleccionada al componente SolGralComponent
    this.solicitudSeleccionada = this.objSol;
    this.orden = this.arrOrders;

    // console.log(this.request.request);
  }
  clonar(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  updateSol() {
    console.log(this.requestSeleccionada);
  }

  saveEdit(request) {
    const PO = this.arrOrders.find(element => element.id === request.id);
    this.dealMemoService
      .updatePO(this.idCompany, this.idProject, PO.id, PO)
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
        $('#editSolicitud').modal('hide');
      })
      .catch(err => {
        console.error('Ocurrio un error' + err);
      });
    // Notiflix.Confirm.Show(
    //   'Actualizar Solicitud',
    //   'Estas seguro de querer actualizar',
    //   'Si',
    //   'No',
    //   () => {
    //   }
    // );
  }

  actualizarTemporal(request) {
    if (request.comprobantes) {
      console.log('tiene comprobantes');
      request.valores = request.comprobantes;
      delete request.comprobantes;
    } else {
      const valores = [
        {
          concepto: request.concepto || '',
          fechaComp: request.fechaComp || null,
          folio: request.folio || '',
          importe: request.importe,
          iva: request.iva || 0,
          retIva: request.retIva || 0,
          retIsr: request.retIsr || 0,
          total: request.total,
          nombreProveedor: request.nombreProveedor,
          partidaPres: request.partidaPres || '',
          partidaPresIva: request.partidaPresIva || null,
          fechaInicio: request.fechaInicio || null,
          fechaFin: request.fechaFin || null,
          rfc: request.rfc || '',
        },
      ];
      request.valores = valores;
    }
    this.saveEdit(request);
  }
}
