import {
  Component,
  OnInit,
  OnDestroy,
  SimpleChanges,
  Input,
} from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import { NgxXml2jsonService } from 'ngx-xml2json';

import Notiflix from 'notiflix-angular';

import { Router } from '@angular/router';
import { BudgetService } from 'src/app/services/budget.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AuthService } from 'src/app/services/auth.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Solicitud, Valores } from 'src/app/models/solicitud';
import { ProjectService } from 'src/app/services/project.service';
import { Period } from 'src/app/models/period';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';
import { GeneralService } from 'src/app/services/general.service';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-sol-gral',
  templateUrl: './sol-gral.component.html',
  styleUrls: ['./sol-gral.component.css'],
})
export class SolGralComponent implements OnInit {
  @Input() request: any; // Recibe la solicitud seleccionada
  @Input() pucharse: any; // Recibe las ordenes de compra encontradas

  ngOnChanges(changes: SimpleChanges) {
    if (changes['request'] && changes['request'].currentValue) {
      // console.log('Solicitud recibida en SolGralComponent:', this.request);
      // console.log('orden recibida en SolGralComponent:', this.pucharse);
      this.request = this.request;
      let orders = [] as any;
      orders = this.pucharse;

      // Filtrar órdenes que correspondan al mismo proveedor
      const ordenesProveedor = orders.filter(
        order => order.rfc === this.request.rfc
      );

      if (ordenesProveedor.length > 0) {
        this.arrOrders = ordenesProveedor;

        // Verificar si alguna orden ya está asociada con esta solicitud
        const ordenAsociada = ordenesProveedor.find(order =>
          order.solicitudes.some(
            solicitud => solicitud.countRequest === this.request.countRequest
          )
        );

        if (ordenAsociada) {
          // Seleccionar automáticamente la orden asociada
          this.indexOrdenSeleccionada = this.arrOrders.indexOf(ordenAsociada);
          this.ordenSeleccionada = ordenAsociada;
        }
      }
      // Seleccionar automáticamente el comprometido asociado
      const comprometidoAsociado = this.ordenSeleccionada.comprometidos.find(
        (comprometido, index) => {
          const isSelected = this.ordenSeleccionada.solicitudes.some(
            solicitud =>
              solicitud.valores.some(
                valor => valor.partidaPres === comprometido.partidaPres
              )
          );
          if (isSelected) {
            this.indexComprometidoSeleccionado = index; // Asignar el índice del comprometido
            this.comprometidoSeleccionado = comprometido; // Asignar el comprometido seleccionado
            this.arrComprometidosSeleccionados = [comprometido]; // Agregarlo al array de seleccionados
            console.log(
              'Comprometido seleccionado automáticamente:',
              comprometido
            );
            return true;
          }
          return false;
        }
      );

      this.comprometidosSeleccionados = this.ordenSeleccionada.comprometidos;

      if (!comprometidoAsociado) {
        console.log(
          'No se encontró un comprometido asociado con esta solicitud.'
        );
      }

      this.changeSelectProvider();
    }
  }
  step = 0;

  providers = [] as any;
  provider = {} as any;
  proveedorSeleccionado = '';

  countRequest: number;
  idCompany: string;
  idProject: string;
  company = {} as any;
  project = {} as any;

  arrCountRequest = [] as any;
  order = {} as PurchaseOrder;

  budget = [] as any;
  fileError = [] as any;
  arrXML = [] as any;
  arrXMLGeneral = [] as any;
  xml = {} as any;
  nameXML: string;
  public rfcReceptor = '';
  user: string;
  periods = [] as any;
  periodoActual = {} as Period;
  binding: any;
  changePeriod = false;
  arrayFechas = [] as any;
  filmadoras = [];
  changeFilmadora = false;
  ordenesEncontradas = [] as any;
  changeCountRequest = false;
  arrImportador = [] as any;
  arrOrders = [] as any;
  indexOrdenSeleccionada: number = undefined;
  indexComprometidoSeleccionado: number = undefined;
  ordenSeleccionada = {} as any;
  comprometidosSeleccionados = [] as any;
  comprometidoSeleccionado = {} as any;
  fechaComprobante: string;
  fechaPropuesta: string;
  acumulado = 0;
  countDealMemo = {} as any;
  existeOrden: boolean;
  editCuenta = false;
  acumuladoTemporal = 0;
  disponible = 0;
  acumuladoCuenta = 0;
  disponibleCuenta = 0;
  acumuladoCuentaIva = 0;
  disponibleCuentaIva = 0;
  disponibleDelComprometido = 0;
  addValue = {} as any;
  arrAcumulados = [] as any;
  bitacora = [] as any;
  isEdit: any;
  comprobante = {
    partidaPres: 'Selecciona una partida',
  } as Valores;
  sumas = {
    importe: 0,
    iva: 0,
    total: 0,
  } as any;
  dataFile = {} as any;
  dataFindPO: string;
  total = {} as any;
  activarValores = false;

  // Subscriptions
  getProviderSubscription: Subscription;
  subscribeCountRequest: Subscription;
  getCompanySubscription: Subscription;
  subscriberGetProject: Subscription;
  subscriberGetUser: Subscription;
  subscriberBudget: Subscription;
  subscriberGetPurchaseOrder: Subscription;
  subscribePeriods: Subscription;
  subscribeGetCFDI: Subscription;
  subscribeCountDealMemo: Subscription;
  getPOSubscription: Subscription;
  findOrderSubscription: Subscription;
  findOrderIvaSubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private requestService: RequestService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService,
    private budgetService: BudgetService,
    private dealMemoService: DealMemoService,
    private empresasService: EmpresasService,
    private authService: AuthService,
    private projectService: ProjectService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isEdit = url.root.children.primary.segments[4].path;

    const hoy = new Date();
    const year = hoy.getFullYear();
    const month = (hoy.getMonth() + 1).toString().padStart(2, '0');
    const day = hoy.getDate().toString().padStart(2, '0');
    this.fechaPropuesta = `${year}-${month}-${day}`;

    console.log(this.isEdit);
    if (this.isEdit === 'consultSol') {
      this.validadorSteps();
    }

    this.request = {
      countRequest: undefined,
      concepto: '',
      cfdi: {
        path: '',
        url: '',
      },
      fechaSol: undefined,
      fechaPropuesta: undefined,
      user: '',
      tipo: 'Directa',
      tipoDirecta: 'CFDI',
      moneda: '',
      TC: 0,
      periodo: '',
      etapa: '',
      filmadora: {
        name: '',
        rfc: '',
      },
      aprobadoPor: '',
      valores: [
        // {
        //   concepto: '',
        //   fechaComp: null,
        //   folio: '',
        //   importe: 0,
        //   iva: 0,
        //   retIva: 0,
        //   retIsr: 0,
        //   total: 0,
        //   nombreProveedor: '',
        //   partidaPres: '',
        //   partidaPresIva: '',
        //   fechaInicio: undefined,
        //   fechaFin: undefined,
        //   rfc: '',
        // },
      ],
      deducible: 'deducible',
    } as Solicitud;
    // console.log(this.isEdit);
    this.getCount();
    this.getPeriods();
    this.getCompany();
    this.getBudget();
    this.getProvider();
    this.getXML();
    this.getUser();
  }

  getCompany() {
    this.getCompanySubscription = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        const objFilmadora = {
          name: res.name,
          rfc: res.rfc,
        };
        this.filmadoras.push(objFilmadora);
        this.company = res;
        this.rfcReceptor = res.rfc.toUpperCase();
        this.getProject();
      });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.project = res;
        this.request.moneda = res.monedaLocal;
        this.request.TC = res.tipoCambioGral;

        if (res.filmadoras) {
          this.filmadoras = this.filmadoras.concat(res.filmadoras);
        }
        this.request.filmadora = this.filmadoras[0];
      });
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta función desglosa todo el presupuesto
          //console.log(budget); // este es el array que contiene hijos (children)
          let arrFilter = arrBudget.filter(
            obj => obj.CodigoAgrupador.split('-').length === 3
          );
          this.budget = arrFilter; // Este es el array que mostramos en la lista
          // console.log(this.budget);
        }
      });
  }

  selectFilmadora() {
    this.changeFilmadora = false;
  }

  getUser() {
    this.subscriberGetUser = this.authService.userData$.subscribe(res => {
      this.user = res.email;
    });
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }

  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.binding = this.periodoActual;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        const fechaProp = this.getLastFriday(fin);
        this.fechaPropuesta = `${fechaProp.getFullYear()}-${(
          fechaProp.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}-${fechaProp
          .getDate()
          .toString()
          .padStart(2, '0')}`;
        // this.request.valores[0].fechaInicio = inicio;
        // this.request.valores[0].fechaFin = fin;
        break;
      }
    }
  }

  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    // this.request.valores[0].fechaInicio = this.binding.fechaInicio;
    // this.request.valores[0].fechaFin = this.binding.fechaFin;
    // const  = this.getLastFriday(this.binding.fechaFin);
    this.request.etapa = this.binding.etapa;
    this.changePeriod = false;
  }

  getLastFriday(date: Date): Date {
    const lastFriday = new Date(date);
    const day = lastFriday.getDay();
    let diff: number;
    if (day === 5) {
      diff = 0;
    } else if (day < 5) {
      diff = day + 2;
    } else if (day > 5) {
      diff = 1;
    }
    lastFriday.setDate(lastFriday.getDate() - diff);
    return lastFriday;
  }

  getCount() {
    this.subscribeCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        console.log(res);
        if (res === undefined) {
          this.countRequest = 1;
          this.request.countRequest = this.countRequest;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
          for (let index = 0; index < res.countRequest.length; index++) {
            const element = res.countRequest[index];
            console.log(element, index + 1);
            if (element !== index + 1) {
              if (index + 1 > 1) {
                this.countRequest = res.countRequest[index - 1] + 1;
                this.request.countRequest = this.countRequest;
                break;
              } else {
                this.countRequest = 1;
                this.request.countRequest = this.countRequest;
                break;
              }
            }
          }
        }
      });
  }

  countRequestManual() {
    if (this.isEdit === 'consultSol') {
      if (!this.subscribeCountRequest.closed) {
        this.subscribeCountRequest.unsubscribe();
      }
      // console.log(this.countRequest);
      // console.log(this.request.countRequest);
      // console.log(this.arrCountRequest);

      const encontrado = this.arrCountRequest.find(
        element => element === this.countRequest
      );

      if (encontrado !== undefined) {
        Notiflix.Notify.Failure(
          `La solicitud numero ${this.countRequest} ya existe`
        );
        this.getCount();
      } else {
        this.request.countRequest = this.request.countRequest;
      }
    } else {
      if (!this.subscribeCountRequest.closed) {
        this.subscribeCountRequest.unsubscribe();
      }
      console.log(this.countRequest);
      console.log(this.arrCountRequest);

      const encontrado = this.arrCountRequest.find(
        element => element === this.countRequest
      );

      if (encontrado !== undefined) {
        Notiflix.Notify.Failure(
          `La solicitud numero ${this.countRequest} ya existe`
        );
        this.getCount();
      } else {
        this.request.countRequest = this.countRequest;
      }
    }
  }

  getProvider() {
    this.getProviderSubscription = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  getCountOrder() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getCountDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countDealMemo.countDealMemo = 1;
        } else {
          this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
        }
        this.order.orderCounter = this.countDealMemo.countDealMemo;
      });
  }

  seleccionOrden(item, i) {
    if (this.step === 2) {
      this.indexComprometidoSeleccionado = undefined;
      this.comprometidoSeleccionado = {};
      this.arrComprometidosSeleccionados = [] as any;
      this.arrIndexComp = [] as any;
      this.ordenSeleccionada = item;
      this.indexOrdenSeleccionada = i;
      this.arrayFechas = [] as any;
      console.log('Orden seleccionada', this.ordenSeleccionada);
      this.arrAcumulados = [] as any;

      // Recorro los comprometidos de la orden seleccionada
      item.comprometidos.forEach((elementComprometidos, i) => {
        let suma = 0;
        // Recorro las solicitudes de la orden seleccionada
        this.ordenSeleccionada.solicitudes.forEach(elementSolicitudes => {
          // Recorro los valores de la solicitud
          elementSolicitudes.valores.forEach(elementValores => {
            if (elementValores.arrIndexComp === undefined) {
              elementValores.arrIndexComp = [
                {
                  index: 0,
                  importe: elementValores.importe,
                },
              ];
            }
            elementValores.arrIndexComp.forEach(elementIndex => {
              // Si el index del comprometido es igual al index del comprometido actual
              if (i === elementIndex.index) {
                // Acumulo el importe de la solicitud sin iva
                suma += elementIndex.importe;
              }
            });
          });
        });
        this.arrAcumulados.push(suma);
      });

      console.log(this.arrAcumulados);

      this.request.moneda = item.moneda;
      this.request.TC = item.TC;

      item.comprometidos.forEach(element => {
        if (element.etapa) {
          const dateInicio = new Date(element.fechaInicio.seconds * 1000);
          const dateFin = new Date(element.fechaFin.seconds * 1000);

          this.arrayFechas.push({
            fechaInicio: dateInicio.toLocaleDateString(),
            fechaFin: dateFin.toLocaleDateString(),
          });
        }
      });
      this.comprometidosSeleccionados = item.comprometidos;
      this.request.aprobadoPor = this.ordenSeleccionada.aprobadoPor;
      this.request.solicitadoPor = this.ordenSeleccionada.aprobadoPor;
    }
  }

  arrComprometidosSeleccionados: any[] = [];
  sumaAcumulados: number = 0;
  arrIndexComp: any[] = [];

  selectComprometido(item, i) {
    if (this.step === 3) {
      const index = this.arrComprometidosSeleccionados.indexOf(item);
      if (index === -1) {
        // Si el elemento no está seleccionado, agregarlo al array
        if (this.arrComprometidosSeleccionados.length > 0) {
          // Si ya hay elementos seleccionados, validar que no sean de diferentes partidas
          if (
            this.arrComprometidosSeleccionados[0].partidaPres ===
            item.partidaPres
          ) {
            this.sumaAcumulados += this.arrAcumulados[i];
            this.arrComprometidosSeleccionados.push(item);
            this.arrIndexComp.push({
              index: i,
              disponible: item.importe * item.cantidad - this.arrAcumulados[i],
            });
            this.comprobante.partidaPres = item.partidaPres;
            this.comprobante.partidaPresIva = item.partidaPresIva;
          } else {
            Notiflix.Notify.Failure(
              'No puedes seleccionar comprometidos de diferentes partidas'
            );
          }
        } else {
          this.sumaAcumulados += this.arrAcumulados[i];
          this.arrIndexComp.push({
            index: i,
            disponible: item.importe * item.cantidad - this.arrAcumulados[i],
          });
          this.arrComprometidosSeleccionados.push(item);
          this.comprobante.partidaPres = item.partidaPres;
          this.comprobante.partidaPresIva = item.partidaPresIva;
        }
      } else {
        // Si el elemento ya está seleccionado, eliminarlo del array
        this.sumaAcumulados -= this.arrAcumulados[i];
        this.arrComprometidosSeleccionados.splice(index, 1);
        this.arrIndexComp.splice(index, 1);
      }
      console.log(
        'this.arrComprometidosSeleccionados',
        this.arrComprometidosSeleccionados
      );
      console.log('this.arrIndexComp', this.arrIndexComp);

      let suma = 0;
      this.arrComprometidosSeleccionados.forEach(element => {
        suma += element.importe * element.cantidad;
      });
      console.log(suma);

      // console.log('Comprometido seleccionado', item);
      // this.comprometidoSeleccionado = item;
      // this.indexComprometidoSeleccionado = i;

      // let importe = item.importe * item.cantidad;
      // console.log('Acumulado', this.arrAcumulados);
      this.disponibleDelComprometido = suma - this.sumaAcumulados;
      console.log(
        'Disponible del comprometido',
        this.disponibleDelComprometido
      );
    }
  }

  guardarModalCuentas() {
    $('#modalEditCtas').modal('show');
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.arrImportador = res.arrXML;
        }
      });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e, archivo);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    // (<any>document.getElementById('inputXML')).value = '';
  }

  xmlToJson(lector, archivo) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xmlData = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xmlData);
    console.log(obj);
    this.xml = this.generalService.assignDataCFDI(obj);
    console.log('XML', this.xml);
    // this.changeSelectProvider();
    this.validarXML();
  }

  validarXML() {
    // Validar si se selecciona orden de compra o no
    console.log(this.ordenSeleccionada);
    if (this.ordenSeleccionada.rfc) {
      console.log('Tiene orden de compra seleccionada');
      // Valido que el xml no exista en la orden seleccionada.
      let validacionXml = true;
      let asociadoASol: any;

      this.ordenSeleccionada.xml.forEach(xmlOrden => {
        if (xmlOrden.folioComprobante === this.xml.folioComprobante) {
          validacionXml = false;
          asociadoASol = xmlOrden.request;
        }
      });

      // valido que el xml no exista en otra orden de compra encontradas con el rfc del proveedor
      if (validacionXml) {
        this.ordenesEncontradas.forEach(elementOrder => {
          elementOrder.xml.forEach(elementXml => {
            if (elementXml.folioComprobante === this.xml.folioComprobante) {
              validacionXml = false;
              asociadoASol = elementXml.request;
            }
          });
        });
      }

      // Valido que no exista en esta solicitud
      if (validacionXml) {
        this.arrXML = [] as any;
        this.request.valores.forEach(elementVal => {
          if (elementVal.folio === this.xml.folioComprobante) {
            validacionXml = false;
            asociadoASol = `${this.request.countRequest} (en esta solicitud)`;
          }
        });
      }

      if (validacionXml) {
        this.xml.asociado = true;
        this.xml.request = this.countRequest;
        this.validarProveedor();
      } else {
        Notiflix.Notify.Failure(
          `El XML ya se encuentra asociado a la solicitud ${asociadoASol}`
        );
        (<any>document.getElementById('formFile')).value = '';
      }
    }
  }

  validarProveedor() {
    // Valido que el rfc del xml sea igual al del proveedor seleccionado
    console.log(this.xml.rfc, this.provider.rfc);
    console.log(this.xml, this.provider);
    if (this.xml.rfc === this.provider.rfc) {
      console.log('El rfc si es igual al proveedor seleccionado.');
      // Si es igual, entonces valido el importe sea menor al disponible del comprometido
      // Solo aplica si tiene Orden de compra seleccionada

      const provider = this.ordenSeleccionada.proveedor.find(
        provider => provider.rfc === this.provider.rfc
      );
      console.log(provider);

      if (this.xml.regimen === provider.regimen) {
        console.log('El regimen si es igual al proveedor seleccionado.');
        let sumaAgregado = 0;

        this.request.valores.forEach(element => {
          sumaAgregado += element.importe;
        });

        console.log(
          'Disponible del comprometido = ' + this.disponibleDelComprometido
        );
        console.log('-');
        console.log('Lo que ya esta agregado' + sumaAgregado);
        console.log(
          'Disponible = ' + (this.disponibleDelComprometido - sumaAgregado)
        );
        console.log('Importe del XML = ' + this.xml.importe);
        console.log(
          'Nuevo disponible = ' +
            (this.disponibleDelComprometido - sumaAgregado - this.xml.importe)
        );

        if (this.ordenSeleccionada.moneda === this.xml.moneda) {
          console.log('Moneda igual');
          if (
            this.disponibleDelComprometido - sumaAgregado >=
            this.xml.importe
          ) {
            this.comprobante.arrIndexComp = [] as any;
            let importeDisponible = this.xml.importe;
            this.arrIndexComp.forEach(element => {
              if (importeDisponible > 0 && element.disponible > 0) {
                if (importeDisponible <= element.disponible) {
                  this.comprobante.arrIndexComp.push({
                    index: element.index,
                    importe: importeDisponible,
                  });
                  element.disponible -= importeDisponible;
                  importeDisponible = 0;
                } else {
                  this.comprobante.arrIndexComp.push({
                    index: element.index,
                    importe: element.disponible,
                  });
                  importeDisponible -= element.disponible;
                  element.disponible = 0;
                }
              }
            });
            this.comprobante.concepto = this.xml.concepto;
            if (this.xml.conceptos) {
              this.comprobante.conceptos = this.xml.conceptos;
            }
            this.comprobante.nombreProveedor = this.provider.nombreProveedor;
            this.comprobante.fechaComp = this.xml.fecha;
            this.comprobante.folio = this.xml.folioComprobante;
            this.comprobante.importe = this.xml.importe;
            this.comprobante.iva = this.xml.iva || 0;
            this.comprobante.retIva = this.xml.retIVA || 0;
            this.comprobante.retIsr = this.xml.retISR || 0;
            this.comprobante.otrasCont = this.xml.otrasCont || 0;
            this.comprobante.total = this.xml.total;
            this.addComprobante();
          } else {
            Notiflix.Notify.Failure(
              'El valor del XML excede el valor disponible de la Orden de compra'
            );
            (<any>document.getElementById('formFile')).value = '';
          }
        } else {
          console.log('Moneda Diferente');
          if (
            this.disponibleDelComprometido - sumaAgregado >=
            this.xml.importe / this.request.TC
          ) {
            this.comprobante.arrIndexComp = [] as any;
            let importeDisponible = this.xml.importe;
            this.arrIndexComp.forEach(element => {
              if (importeDisponible > 0 && element.disponible > 0) {
                if (importeDisponible <= element.disponible) {
                  this.comprobante.arrIndexComp.push({
                    index: element.index,
                    importe: importeDisponible,
                  });
                  element.disponible -= importeDisponible;
                  importeDisponible = 0;
                } else {
                  this.comprobante.arrIndexComp.push({
                    index: element.index,
                    importe: element.disponible,
                  });
                  importeDisponible -= element.disponible;
                  element.disponible = 0;
                }
              }
            });
            this.request.moneda = this.xml.moneda;
            this.comprobante.concepto = this.xml.concepto;
            if (this.xml.conceptos) {
              this.comprobante.conceptos = this.xml.conceptos;
            }
            this.comprobante.nombreProveedor = this.provider.nombreProveedor;
            this.comprobante.folio = this.xml.folioComprobante;
            this.comprobante.importe = this.xml.importe;
            this.comprobante.iva = this.xml.iva || 0;
            this.comprobante.retIva = this.xml.retIVA || 0;
            this.comprobante.retIsr = this.xml.ret || 0;
            this.comprobante.total = this.xml.total;
            this.addComprobante();
          } else {
            Notiflix.Notify.Failure(
              'El valor del XML excede el valor disponible de la Orden de compra'
            );
            (<any>document.getElementById('formFile')).value = '';
          }
        }
      } else {
        Notiflix.Notify.Failure(
          'El regimen del xml no corresponde al proveedor seleccionado.'
        );

        // Preguntar si desea modificar el proveedor, y redirija a la pantalla de proveedores

        (<any>document.getElementById('formFile')).value = '';
      }
    } else {
      Notiflix.Notify.Failure(
        'El RFC del xml no corresponde al proveedor seleccionado.'
      );
      (<any>document.getElementById('formFile')).value = '';
    }
  }

  changeSelectProvider() {
    if (this.isEdit === 'consultSol') {
      this.provider = {} as any;
      let proveedor;
      this.proveedorSeleccionado = this.request.numeroProveedor;
      const numProvider = this.proveedorSeleccionado;
      proveedor = this.providers.find(
        provider => provider.numero === parseInt(numProvider)
      );
      this.provider.numeroProveedor = proveedor.numero;
      this.provider.nombreProveedor = proveedor.nombre;
      this.provider.rfc = proveedor.rfc;
    } else if (this.isEdit === 'solGral') {
      this.arrOrders = [] as any;
      this.ordenSeleccionada = {};
      this.comprometidoSeleccionado = {} as any;
      this.comprometidosSeleccionados = [] as any;
      this.xml = {} as any;
      this.request.valores = [] as any;
      this.comprobante = {} as any;
      this.provider = {} as any;
      let proveedor;
      if (this.xml.rfc) {
        proveedor = this.providers.find(
          provider => provider.rfc === this.xml.rfc
        );
      } else {
        console.log(this.proveedorSeleccionado);
        const numProvider = this.proveedorSeleccionado.split('-')[0];
        proveedor = this.providers.find(
          provider => provider.numero === parseInt(numProvider)
        );
      }
      if (proveedor !== undefined) {
        this.provider.numeroProveedor = proveedor.numero;
        this.provider.nombreProveedor = proveedor.nombre;
        this.provider.rfc = proveedor.rfc;
        this.getPurchaseOrder();
      } else {
        Notiflix.Notify.Failure('El proveedor no se encuentra registrado');
        // ------
        Notiflix.Confirm.Show(
          'Agregar Proveedor',
          'Deseas agregar el proveedor a la base de datos?',
          'Si',
          'No',
          () => {
            const provider = {} as any;
            provider.nombre = this.xml.proveedor;
            provider.rfc = this.xml.rfc;
            provider.regimen = this.xml.regimen;
            provider.lugarExpedicion = this.xml.lugarExpedicion;
            provider.persona = provider.rfc.length === 12 ? 'Moral' : 'Física';
            provider.nacionalidad = 'Mexicana';
            provider.numero = this.providers.length + 1;
            this.providers.push(provider);
            const objProviders = Object.assign({}, this.providers);
            this.providersService.addProvider(objProviders).then(() => {
              Notiflix.Notify.Success('Proveedor agregado exitosamente');
              this.changeSelectProvider();
            });
          },
          () => {}
        );
      }
    }
  }

  selectCtaIva() {
    const objIva = this.budget.find(
      element =>
        element.CodigoAgrupador.split('-')[0] ===
          this.comprobante.partidaPres.split('-')[0] &&
        element.Descripcion === 'Total Fringes'
    );

    if (objIva) {
      this.comprobante.partidaPresIva = objIva.CodigoAgrupador;
    } else {
      this.comprobante.partidaPresIva = '0150';
    }
  }

  getPurchaseOrder() {
    this.indexOrdenSeleccionada = undefined;
    this.arrOrders = [];
    this.getPOSubscription = this.dealMemoService
      .getPurchaseOrderRFC(this.idCompany, this.idProject, this.provider.rfc)
      .subscribe(res => {
        if (res.length === 0) {
          this.existeOrden = false;
        } else {
          console.log('tiene orden de compra pero no se a seleccionado');
          this.existeOrden = true;
        }
        this.getCountOrder();
        this.ordenesEncontradas = res;

        if (this.xml.rfc) {
          // Si el xml tiene rfc (Aun no existe el XML)
          this.arrXML = [] as any;
          console.log(res);
          res.forEach(elementOrder => {
            elementOrder.xml.forEach(elementXml => {
              this.arrXML.push(elementXml);
            });
          });
          this.arrXML.concat(this.arrImportador);
          this.validarSiExiste();
        } else {
          this.arrOrders = this.ordenesEncontradas;
        }
        this.getPOSubscription.unsubscribe();
      });
  }

  validarSiExiste() {
    // Esta funcion Regresa el index del documento si encuentra el folio en el array
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === this.xml.folioComprobante
    );
    const XML = this.arrXML[indexXML];

    // -------------------------

    // Esta funcion Busca el rfc de empresa receptora en la empresa
    let buscarRfc = -1;
    if (this.project.filmadoras) {
      buscarRfc = this.project.filmadoras.findIndex(
        element => element.rfc === this.xml.rfcReceptor.toUpperCase()
      );
    }
    // --------------------------
    let validacionXML = true;

    if (indexXML === -1) {
      // console.log('El XML no existe en el array');
      const indexFilmadora = this.filmadoras.findIndex(
        element => element.rfc === this.xml.rfcReceptor.toUpperCase()
      );

      if (indexFilmadora >= 0) {
        validacionXML = true;
        this.xml.asociado = true;
        this.xml.request = this.countRequest;
      } else {
        console.log('El RFC receptor no corresponde a la filmadora');
        Notiflix.Notify.Failure(
          'El RFC receptor no corresponde a la filmadora'
        );
        this.provider = {} as any;
      }
    } else if (indexXML > -1) {
      // console.log('El XML ya existe');
      if (XML.asociado) {
        // console.log('y ya esta asociado');
        Notiflix.Notify.Failure(
          `El folio ${this.xml.folioComprobante} ya se encuentra asociado a la solicitud ${XML.request}.`
        );
        this.provider = {} as any;
      } else {
        // console.log('pero no esta asociado');
        this.arrXML[indexXML].asociado = true;
        this.arrXML[indexXML].request = this.countRequest;

        this.xml = this.arrXML[indexXML];

        this.request.concepto = this.arrXML[indexXML].concepto;
        this.request.valores[0].folio = this.arrXML[indexXML].folioComprobante;
        this.request.valores[0].fechaComp = this.arrXML[indexXML].fecha;
        this.request.valores[0].importe = this.arrXML[indexXML].importe;
        this.request.valores[0].iva = this.arrXML[indexXML].iva || 0;
        this.request.valores[0].retIva = this.arrXML[indexXML].retIVA || 0;
        this.request.valores[0].retIsr = this.arrXML[indexXML].retISR || 0;
        this.request.valores[0].total = this.arrXML[indexXML].total;
      }
    }
  }

  validarAcumulado() {
    if (this.comprometidoSeleccionado.cantidad) {
      if (
        this.request.valores[0].importe >
        this.comprometidoSeleccionado.cantidad *
          this.comprometidoSeleccionado.importe -
          this.arrAcumulados[this.indexComprometidoSeleccionado]
      ) {
        console.log('Paso 1');
        Notiflix.Notify.Failure('El importe excede el valor disponible');
        this.request.valores[0].importe = 0;
        this.request.valores[0].iva = 0;
        this.request.valores[0].retIsr = 0;
        this.request.valores[0].retIva = 0;
        this.request.valores[0].total = 0;
      }
    } else {
      console.log(this.disponible);
      if (this.request.valores[0].importe > this.disponible) {
        Notiflix.Notify.Failure('El importe excede el valor disponible');
        console.log('Paso 2');
        this.request.valores[0].importe = 0;
        this.request.valores[0].iva = 0;
        this.request.valores[0].retIsr = 0;
        this.request.valores[0].retIva = 0;
        this.request.valores[0].total = 0;
      }
    }
  }

  acumularPago() {
    this.arrOrders[this.indexOrdenSeleccionada].comprometidos[
      this.indexComprometidoSeleccionado
    ].acumulado = this.acumulado;
  }

  addManual() {
    let sumaAgregado = 0;

    const validado = () => {
      this.comprobante.arrIndexComp = [] as any;
      let importeDisponible = this.comprobante.importe;
      this.arrIndexComp.forEach(element => {
        if (importeDisponible > 0 && element.disponible > 0) {
          if (importeDisponible <= element.disponible) {
            this.comprobante.arrIndexComp.push({
              index: element.index,
              importe: importeDisponible,
            });
            element.disponible -= importeDisponible;
            importeDisponible = 0;
          } else {
            this.comprobante.arrIndexComp.push({
              index: element.index,
              importe: element.disponible,
            });
            importeDisponible -= element.disponible;
            element.disponible = 0;
          }
        }
      });
      this.addComprobante();
    };

    this.request.valores.forEach(element => {
      sumaAgregado += element.importe;
    });
    if (
      this.comprobante.partidaPres &&
      this.comprobante.partidaPresIva &&
      this.comprobante.importe > 0
    ) {
      if (this.ordenSeleccionada.moneda === this.request.moneda) {
        console.log('Moneda igual');
        if (
          this.disponibleDelComprometido - sumaAgregado >=
          this.comprobante.importe
        ) {
          validado();
        } else {
          Notiflix.Notify.Failure(
            'El valor excede el valor disponible de la Orden de compra'
          );
          (<any>document.getElementById('formFile')).value = '';
        }
      } else {
        console.log('Moneda Diferente');
        if (
          this.disponibleDelComprometido - sumaAgregado >=
          this.comprobante.importe / this.request.TC
        ) {
          validado();
        } else {
          Notiflix.Notify.Failure(
            'El valor excede el valor disponible de la Orden de compra'
          );
          (<any>document.getElementById('formFile')).value = '';
        }
      }
    } else {
      Notiflix.Notify.Failure('Favor de llenar todos los campos');
    }
  }

  addComprobante() {
    console.log(this.comprobante);
    // Falta Validar Que se llenen los campos

    if (this.comprobante.importe > 0) {
      this.activarValores = true;
    }

    this.request.valores.push(this.comprobante);
    if (this.request.concepto.length === 0) {
      this.request.concepto = this.comprobante.concepto;
    }
    this.comprobante = {
      partidaPres: this.comprometidoSeleccionado.partidaPres,
      partidaPresIva: this.comprometidoSeleccionado.partidaPresIva,
    } as any;
    if (this.ordenSeleccionada.proveedor.length > 0) {
      this.comprobante.regimen = this.ordenSeleccionada.proveedor[0].regimen;
    }
    (<any>document.getElementById('formFile')).value = '';
    this.activarValores = false;
    this.totales();
  }

  createOrder() {
    this.order = {
      orderCounter: this.countDealMemo.countDealMemo,
      numeroProveedor: this.provider.numeroProveedor,
      nombreProveedor: this.provider.nombreProveedor,
      rfc: this.provider.rfc,
      proveedor: [this.provider],
      comprometidos: [
        {
          partidaPres: this.request.valores[0].partidaPres,
          partidaPresIva: this.request.valores[0].partidaPresIva,
          cantidad: 1,
          importe: this.request.valores[0].importe,
          iva: this.request.valores[0].iva,
          total: this.request.valores[0].total,
          fechaInicio: this.periodoActual.fechaInicio,
          fechaFin: this.periodoActual.fechaFin,
        },
      ],
      cuentas: [],
      actividad: this.request.concepto,
      TC: this.request.TC,
      moneda: this.request.moneda,
      fechaCreado: new Date(),
      creadoPor: this.user,
      aprobadoPor: this.request.aprobadoPor,
      solicitudes: [this.request],
      tipo: 'PODIRECTA',
      calendario: true,
      xml: [],
      filmadora: this.request.filmadora,
      tipoPago: '',
    };
    if (this.xml.rfc) {
      this.order.xml.push(this.xml);
    }
    this.request.valores.forEach(element => {
      this.order.cuentas.push(element.partidaPres);
    });
  }

  saveSol() {
    console.log(this.request);
    // Se ejecuta si tiene Orden de compra
    if (this.existeOrden && this.ordenSeleccionada.rfc) {
      // this.acumularPago();
      this.request.fechaSol = new Date();
      this.request.fechaPropuesta = new Date(this.fechaPropuesta + 'T23:59:59');
      this.request.user = this.user;

      if (this.xml.rfc) {
        console.log(this.ordenSeleccionada);
        this.ordenSeleccionada.xml.push(this.xml);
      }
      // console.log(this.arrOrders[this.indexOrdenSeleccionada]);

      this.ordenSeleccionada.solicitudes.push(this.request);
      const objUpdate = {
        solicitudes: this.ordenSeleccionada.solicitudes,
        xml: this.ordenSeleccionada.xml,
      };

      console.log(this.ordenSeleccionada.id);
      console.log(objUpdate);

      this.dealMemoService
        .updatePurchaseOrder(
          this.idCompany,
          this.idProject,
          this.ordenSeleccionada.id,
          objUpdate
        )
        .then(res => {
          Notiflix.Notify.Success('Guardado exitoso');

          const objBit = {
            fecha: new Date(),
            mensaje: `Se creo la solicitud #${this.request.countRequest}`,
            descripcion: `Por un valor de: $${this.request.valores[0].total.toFixed(
              2
            )}`,
            detalle: [
              `Proveedor: ${this.provider.nombreProveedor}`,
              `RFC: ${this.provider.rfc}`,
              `Concepto: ${this.request.concepto}`,
              `Partida: ${this.request.valores[0].partidaPres || 'Sin folio'}`,
              `Folio: ${this.request.valores[0].folio || 'Sin folio'}`,
              `Aprobado por: ${this.request.aprobadoPor}`,
            ],
            id: this.arrOrders[this.indexOrdenSeleccionada].id,
            path: `empresas/${this.idCompany}/proyectos/${
              this.idProject
            }/purchaseOrder/${this.arrOrders[this.indexOrdenSeleccionada].id}`,
            tipo: 'Solicitud',
            user: this.user,
          } as any;
          this.updateBitacora(objBit);

          this.clear();
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrió un error');
          console.error('ERROR: ', err);
        });
    }
  }

  updateBitacora(obj) {
    this.generalService.updateBit(this.idCompany, this.idProject, obj);
  }

  clear() {
    // Guardamos Count Request
    this.arrCountRequest.push(this.countRequest);
    this.requestService.countRequest(
      this.idCompany,
      this.idProject,
      this.arrCountRequest
    );

    // Si esta desuscrito se vuelve a subscribir
    if (this.subscribeCountRequest) {
      this.getCount();
    }
    this.countRequest++;
    // >>>>>>>>>>>>>>>>>>

    this.request = {
      countRequest: 0,
      concepto: '',
      cfdi: {
        path: '',
        url: '',
      },
      fechaSol: undefined,
      fechaPropuesta: undefined,
      user: '',
      tipo: 'Directa',
      tipoDirecta: 'CFDI',
      moneda: '',
      TC: 0,
      periodo: '',
      etapa: '',
      filmadora: {
        name: '',
        rfc: '',
      },
      aprobadoPor: '',
      solicitadoPor: '',
      valores: [
        // {
        //   concepto: '',
        //   fechaComp: null,
        //   folio: '',
        //   importe: 0,
        //   iva: 0,
        //   retIva: 0,
        //   retIsr: 0,
        //   otrasCont: 0,
        //   total: 0,
        //   nombreProveedor: '',
        //   partidaPres: '',
        //   partidaPresIva: '',
        //   fechaInicio: this.periodoActual.fechaInicio,
        //   fechaFin: this.periodoActual.fechaFin,
        //   rfc: '',
        // },
      ],
      deducible: '',
    } as Solicitud;
    this.arrOrders = [] as any;
    this.ordenSeleccionada = [] as any;
    this.provider = {} as any;
    this.request.moneda = this.project.monedaLocal;
    this.request.TC = this.project.tipoCambioGral;
    this.request.filmadora = this.filmadoras[0];
    this.fechaComprobante = '';
    this.indexOrdenSeleccionada = undefined;
    this.indexComprometidoSeleccionado = undefined;
    this.comprometidosSeleccionados = [] as any;
    this.comprometidoSeleccionado = {} as any;
    this.disponibleCuenta = 0;
    this.acumuladoCuenta = 0;
    this.arrXML = [] as any;
    this.xml = {} as any;
    this.proveedorSeleccionado = '';
    this.step = 0;
    this.filtrarperiodo();
  }

  buscarOC(tipo) {
    if (tipo === 'partida') {
      const find = this.dealMemoService
        .findOrders(this.idCompany, this.idProject, this.dataFindPO)
        .subscribe(res => {
          console.log(res);
          if (res.length === 0) {
            Notiflix.Notify.Failure('No se encontraron ordenes de compra');
          }
          this.arrOrders = res;
          find.unsubscribe();
        });
    } else if (tipo === 'OC') {
      this.findOrderSubscription = this.dealMemoService
        .getPurchaseOrderNum(this.idCompany, this.idProject, this.dataFindPO)
        .subscribe(res => {
          console.log(res);
          this.arrOrders = res;
          this.findOrderSubscription.unsubscribe();
        });
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'auto',
      });
    }, 200);
  }

  totales() {
    this.total = {
      importe: 0,
      iva: 0,
      retIva: 0,
      retIsr: 0,
      total: 0,
    };
    this.request.valores.forEach(element => {
      this.total.importe += element.importe;
      this.total.iva += element.iva;
      this.total.retIva += element.retIva;
      this.total.retIsr += element.retIsr;
      this.total.total += element.total;
    });
    // return this.total;
  }

  validadorSteps() {
    console.log(this.step);
    if (this.isEdit === 'consultSol') {
      this.step = 6;
    } else {
      switch (this.step) {
        case 0:
          console.log('validacion1');
          if (!this.changeCountRequest) {
            this.step += 1;
            this.scrollToBottom();
          } else {
            Notiflix.Notify.Failure(
              'Aplica los cambios en el numero de solicitud'
            );
          }
          break;

        case 1:
          console.log('Validacion Proveedor');
          if (this.provider.rfc) {
            this.step += 1;
            this.scrollToBottom();
          } else {
            Notiflix.Notify.Failure('Selecciona un proveedor');
          }
          break;

        case 2:
          console.log('Validacion Orden');
          if (Object.keys(this.ordenSeleccionada).length > 0) {
            this.step += 1;
            this.scrollToBottom();
          } else {
            Notiflix.Notify.Failure('Selecciona una orden de compra');
          }
          break;
        case 3:
          console.log('Validacion comprometido');
          console.log(this.arrComprometidosSeleccionados);
          if (this.arrComprometidosSeleccionados.length > 0) {
            this.step += 1;
            this.scrollToBottom();
          } else {
            Notiflix.Notify.Failure('Selecciona un comprometido');
          }
          break;
        case 4:
          console.log('Validacion concepto');
          if (this.request.aprobadoPor && this.request.solicitadoPor) {
            this.step += 1;
            this.scrollToBottom();
          } else {
            Notiflix.Notify.Failure('Llena todos los campos');
          }
          break;
        case 5:
          console.log('Validacion valores');
          if (this.request.valores.length > 0) {
            this.step += 1;
            this.scrollToBottom();
          } else {
            Notiflix.Notify.Failure('Agrega valores a la solicitud');
          }
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.getProviderSubscription.unsubscribe();
    this.subscribeCountRequest.unsubscribe();
    this.getCompanySubscription.unsubscribe();
    this.subscriberGetProject.unsubscribe();
    this.subscriberGetUser.unsubscribe();
    this.subscriberBudget.unsubscribe();
    // this.subscribeGetCFDI.unsubscribe();
    if (this.subscribeCountDealMemo) {
      this.subscribeCountDealMemo.unsubscribe();
    }

    if (this.subscriberGetPurchaseOrder) {
      this.subscriberGetPurchaseOrder.unsubscribe();
    }
  }
}
